<template>
  <v-dialog
    v-model="showLibrarioesVersionsDialog"
    max-width="500"
    persistent
    :loading="cardLoading"
  >
    <v-card>
      <v-toolbar outlined>
        <span class="text-h6">New Libraries Version </span>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeLibrariesVersionDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <div class="pt-4">
        <v-form lazy-validation v-model="valid" ref="librariesVersionForm">
          <v-card-title class="py-1"> Libraries Categories </v-card-title>
          <v-card-text>
            <v-combobox
              v-model="parentLibrary"
              :rules="rules"
              auto-select-first
              solo
              single-line
              required
              :items="parentLibraries"
              item-text="category"
              label="Select Libraries Version"
            ></v-combobox>

            <v-textarea
              v-model="currentParentLibrary.definition"
              name="input-7-1"
              outlined
              label="Definition"
              item-text="definition"
              :value="currentParentLibrary.definition"
              hint="Hint text"
            ></v-textarea>
          </v-card-text>

          <v-card-text class="justify-end d-flex">
            <v-btn
              color="secondary"
              @click="updateData"
              :disabled="currentParentLibrary.id !== null ? false : true"
              :loading="loading"
            >
              update
            </v-btn>
          </v-card-text>

          <v-card-title class="py-1"> Enter Version </v-card-title>
          <v-card-text>
            <v-text-field
              v-model="version"
              class="py-1 inputChipset"
              hide-details="auto"
              clearable
              prepend-inner-icon="mdi-door"
              return-object
              required
              :rules="rules"
              outlined
              label="Version"
              item-value="id"
              hint="Format: x.x.x"
            ></v-text-field>
          </v-card-text>

          <v-card-text class="justify-end d-flex">
            <v-btn
              color="primary"
              @click="saveData"
              :disabled="!valid || loading"
              :loading="loading"
            >
             add version
            </v-btn>
          </v-card-text>
        </v-form>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import Swal from 'sweetalert2'
import { apiService } from '../../../services/api.service'
export default {
  data () {
    return {
      rules: [(v) => !!v || 'Field is required'],
      compatibleDeviceName: '',
      parentLibrary: '',
      currentParentLibrary: {
        id: null,
        definition: null
      },
      version: null,
      loading: false,
      cardLoading: false,
      valid: true,
      parentLibraries: []
    }
  },
  computed: {
    parentLibraryDefinition () {
      return this.parentLibrary.definition
    }
  },
  mounted () {
    this.getParentLibraries()
  },

  props: {
    showLibrarioesVersionsDialog: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    parentLibrary: function (val) {
      if (val) {
        this.currentParentLibrary = {
          id: val.id,
          definition: val.definition
        }
      } else {
        this.currentParentLibrary = {
          id: null,
          definition: null
        }
      }
    }
  },
  methods: {
    getParentLibraries () {
      this.cardLoading = true
      apiService
        .get('admin/get-parent-libraries-category')
        .then((res) => {
          this.parentLibraries = res.data.data
        })
        .catch((err) => {
          console.log(err.response)
        })
        .finally(() => {
          this.cardLoading = false
        })
    },
    saveData: function () {
      if (this.$refs.librariesVersionForm.validate()) {
        this.loading = true
        const librariesVersionPayload = {
          parent_exp_docker_id: this.parentLibrary.id,
          setting_name: this.version
        }

        apiService
          .post('admin/add-exp-child', librariesVersionPayload)
          .then(() => {
            const form = this.$refs.librariesVersionForm
            if (form) {
              form.reset()
              form.resetValidation()
            }
            this.closeLibrariesVersionDialog()
            this.$store.dispatch('getDockers')
            Swal.fire('Success!', 'Version added.', 'success')
          })
          .catch((err) => {
            Swal.fire('Warning!', `${err.response.data.message}`, 'warning')
            console.log(err.response)
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    updateData: function () {
      apiService
        .put('admin/update-parent-category', this.currentParentLibrary)
        .then(() => {
          Swal.fire('Success!', 'Category is updated.', 'success')
          this.getParentLibraries()
        })
        .catch((err) => {
          Swal.fire('Warning!', `${err.response.data.message}`, 'warning')
          console.log(err.response)
        })
        .finally(() => {
          this.loading = false
        })
    },
    closeLibrariesVersionDialog () {
      this.$emit('close-libraries-version')
    }
  }
}
</script>

  <style>
.inputChipset input[type='number'] {
  -moz-appearance: textfield;
}

.inputChipset input::-webkit-outer-spin-button,
.inputChipset input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>
