<template>
  <div>
    <v-card class="elevation-0">
      <v-card-text>
        <docker-card
          :allSources="allSources"
          :allRoles="allRoles"
          :allJetpacks="allJetpacks"
        ></docker-card>
        <create-docker-view
          v-model="isOpenCreateDockerView"
          :allRoles="allRoles"
          :allSources="allSources"
        ></create-docker-view>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { apiService } from '../../services/api.service'
import CreateDockerView from '../components/dockers/CreateDockerView.vue'
import DockerCard from '../components/dockers/DockerCard.vue'
export default {
  components: { DockerCard, CreateDockerView },
  data () {
    return {
      dockers: null,
      allRoles: null,
      allSources: null,
      allDevices: null,
      allJetpacks: null,
      search: '',
      isOpenCreateDockerView: false,
      loading: {
        sourceAndRoles: true
      }
    }
  },

  beforeMount () {
    this.fetchAllSourcesAndRoles()
  },

  methods: {
    showCreateDockerView () {
      this.isOpenCreateDockerView = true
    },
    fetchAllSourcesAndRoles () {
      apiService
        .get('admin/get-docker-sources')
        .then((res) => {
          this.allSources = res.data.data.sources
          this.allRoles = res.data.data.roles
          this.allDevices = res.data.data.devices
          this.allJetpacks = res.data.data.jetpack
        })
        .finally(() => {
          this.loading.sourceAndRoles = false
        })
    }
  }
}
</script>

<style>
</style>
