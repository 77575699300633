
import converter from '@/mixins/converter'
import { apiService } from '../../../services/api.service'

export default converter.extend({
  data () {
    return {
      loading: false,
      rules: [(v) => !!v || 'Field is required'],
      valid: true,
      update: false,
      version: '',
      l4t: '',
      label: '',
      jetpack: 'Jetpack: '
    }
  },
  props: {
    showJetpackDialog: Boolean,
    showUpdateOrEdit: Boolean,
    jetpackInfo: { type: Object, default: () => null }
  },

  watch: {
    showJetpackDialog: {
      handler: function (n) {
        if (n) {
          if (this.showUpdateOrEdit) {
            this.update = true
            this.label = this.jetpackInfo.label
            this.version = this.jetpackInfo.version
            this.l4t = this.jetpackInfo.l4t
          } else {
            this.update = false
            const form = this.$refs.tagForm
            if (form) {
              form.reset()
              form.resetValidation()
            }
          }
        }
      }
    }
  },

  methods: {
    closeJetpack () {
      this.$emit('close-jetpack')
    },

    saveData: function () {
      if (this.$refs.tagForm.validate()) {
        this.loading = true
        const jetpackPayload = {
          id: null,
          label: this.label,
          version: this.version,
          l4t: this.l4t
        }
        if (this.update) {
          jetpackPayload.id = this.jetpackInfo.id
        }

        apiService
          .post(`admin/jetpack-${this.update ? 'edit' : 'new'}`, jetpackPayload)
          .then((res) => {
            this.$emit('fetch-jetpack-list')
            const form = this.$refs.tagForm
            if (form) {
              form.reset()
              form.resetValidation()
            }
            this.closeJetpack()
          })
          .catch((err) => {
            console.log(err.response)
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
})
