<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card-title>
          <span class="text-h6">Dockers Management</span>
          <v-divider class="ml-2" vertical></v-divider>

          <v-btn icon @click="$store.dispatch('getDockers')">
            <v-icon>mdi-sync</v-icon>
          </v-btn>
          <v-spacer></v-spacer>

          <div class="text-center mr-2">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark v-bind="attrs" v-on="on">
                  Add new list
                </v-btn>
              </template>
              <v-list>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item
                      @click="openJetpackCard"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon left> mdi-plus </v-icon>
                      Add Jetpacks
                    </v-list-item>
                  </template>
                  <span>Add/Remove jetpack-l4t version for jetson</span>
                </v-tooltip>

                <v-list-item @click="openCompatibleDevice">
                  <v-icon left> mdi-plus </v-icon>
                  Add Compatible Device
                </v-list-item>

                <v-list-item @click="openLibrariesVersion">
                  <v-icon left> mdi-plus </v-icon>
                  Add Libraries/Version
                </v-list-item>
              </v-list>
            </v-menu>
          </div>

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search Dockers"
            dense
            hide-details
            clearable
            outlined
            class="mr-2"
          ></v-text-field>

          <v-btn
            color="primary"
            class="ml-2"
            outlined
            @click.stop="showDetailspanel(null)"
          >
            <v-icon left> mdi-plus </v-icon>
            New Docker
          </v-btn>
        </v-card-title>
      </v-col>
    </v-row>
    <v-row v-if="loadingDockers">
      <v-col v-for="c in dummyIterator" :key="`loading_${c}`" md="4" cols="12">
        <v-skeleton-loader
          type="card"
          class="mx-auto elevation-2 w-100"
        ></v-skeleton-loader>
      </v-col>
    </v-row>

    <v-row>
      <v-col md="4" cols="12" v-for="(docker, index) in searching" :key="index">
        <v-card
          :color="docker.deleted ? 'grey lighten-3' : 'white'"
          hover
          class="card-outter"
          height="15em"
        >
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <span class="text-h6 font-weight-bold black--text">
                  <v-chip
                    text-color="white"
                    class="overline"
                    small
                    :color="type_color(docker)"
                    >{{ docker.type }}</v-chip
                  >
                  {{ docker.docker_name }}
                </span>
                <v-chip color="red" label small dark v-if="docker.deleted">
                  Status: Invisible</v-chip
                >
                <v-menu bottom left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="openMenu(docker)"
                      icon
                      v-bind="attrs"
                      v-on="on"
                      class="float-right"
                    >
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item>
                      <v-switch
                        @click="setInvisible(docker.id)"
                        v-model="isVisible"
                      ></v-switch>
                      Set {{ docker.deleted ? 'Visible' : 'Invisible' }}
                    </v-list-item>
                    <v-list-item @click="deleteDocker(docker)">
                      <v-icon left color="red"> mdi-delete </v-icon>
                      Delete Docker
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>

            <div class="mt-1 black--text three-lines">
              {{ docker.short_desc | truncated(150, '...') }}
            </div>
          </v-card-text>
          <v-card-actions class="card-actions">
            <v-btn
              outlined
              color="primary"
              @click.stop="showDetailspanel(docker)"
            >
              View Details
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-col>
      <create-docker-view
        v-if="showDetails"
        v-model="showDetails"
        :dockersInfo="selectedDocker"
        :allSources="this.allSources"
        :allRoles="this.allRoles"
      >
      </create-docker-view>

      <jetpack-table
        v-if="showJetpackTable"
        :showJetpackTable="showJetpackTable"
        @close-jetpack-table="showJetpackTable = false"
      >
      </jetpack-table>

      <add-compatible-device
        :showCompatibleDeviceDialog="showCompatibleDeviceDialog"
        @close-compatible-device="showCompatibleDeviceDialog = false"
      ></add-compatible-device>

      <new-libraries-version
        :showLibrarioesVersionsDialog="showLibrarioesVersionsDialog"
        @close-libraries-version="showLibrarioesVersionsDialog = false"
      ></new-libraries-version>
    </v-row>
  </div>
</template>

<script>
import Vue from 'vue'
import CreateDockerView from './CreateDockerView.vue'
import { apiService } from '../../../services/api.service'
import Swal from 'sweetalert2'
import JetpackTable from './JetpackTable.vue'
import AddCompatibleDevice from './AddCompatibleDevice.vue'
import NewLibrariesVersion from './NewLibrariesVersion.vue'
import { mapGetters } from 'vuex'
export default Vue.extend({
  data () {
    return {
      showDetails: false,
      changedDescription: null,
      changedLabels: null,
      changedDockerName: null,
      showJetpackTable: false,
      isVisible: false,
      search: null,
      selectedDocker: null,
      loading: {
        dockers: false
      },
      currentDocker: {},
      dummyIterator: [...Array(3).keys()],
      ip: null,
      showCompatibleDeviceDialog: false,
      showLibrarioesVersionsDialog: false
    }
  },
  created () {
    this.$store.dispatch('getDockers')
  },
  components: {
    CreateDockerView,
    JetpackTable,
    AddCompatibleDevice,
    NewLibrariesVersion
  },
  props: {
    allSources: Array,
    allRoles: Array
  },
  computed: {
    ...mapGetters(['loadingDockers', 'dockers']),
    searching () {
      if (!this.search) return this.dockers
      const search = this.search.toLowerCase()
      return this.dockers.filter((docker) => {
        const dockerName = this.doLowerCase(docker.docker_name)
        const dockerDescription = docker.desc.toLowerCase()
        const dockerLabels = docker.labels.toLowerCase()
        return (
          dockerName.indexOf(search) > -1 ||
          dockerDescription.indexOf(search) > -1 ||
          dockerLabels.indexOf(search) > -1
        )
      })
    }
  },
  watch: {
    currentDocker: {
      handler (val) {
        this.isVisible = val.deleted
      }
    }
  },
  methods: {
    openMenu (docker) {
      this.isVisible = docker.deleted
    },
    doLowerCase (dockerInfos) {
      return dockerInfos.toLowerCase()
    },

    deleteDocker (docker) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!'
      }).then(async (result) => {
        if (result.value) {
          await apiService
            .delete(`admin/delete-docker/${docker.id}`)
            .then((res) => {
              Swal.fire('Deleted!', 'Licence has been deleted.', 'success')
              this.$store.dispatch('getDockers')
            })
            .catch((err) => {
              Swal.showValidationMessage(`Request failed: ${err}`)
            })
        }
      })
    },
    async setInvisible (dockerId) {
      await apiService
        .post(`admin/set-visible-docker/${dockerId}`, {
          is_visible: this.isVisible
        })
        .then((res) => {
          Swal.fire(
            'Success!',
            `Docker is now ${this.isVisible ? 'invisible' : 'visible'}.`,
            'success'
          )
          this.$store.dispatch('getDockers')
        })
        .catch((err) => {
          Swal.showValidationMessage(`Request failed: ${err}`)
        })
    },
    showDetailspanel (docker) {
      this.selectedDocker = docker
      this.showDetails = true
    },

    /**
     * For UI purpose
     */
    type_color (docker) {
      switch (docker.type) {
        case 'app':
          return 'orange'
        case 'docker':
          return 'blue'
      }
      return 'black'
    },

    openJetpackCard () {
      this.showJetpackTable = true
    },
    openCompatibleDevice () {
      this.showCompatibleDeviceDialog = true
    },

    openLibrariesVersion () {
      this.showLibrarioesVersionsDialog = true
    },

    closeCompatibleDevice () {
      this.showCompatibleDeviceDialog = false
    }
  }
})
</script>

<style scoped>
.card-outter {
  padding-bottom: 50px;
}
.card-actions {
  position: absolute;
  bottom: 0;
}
</style>
